export const NO_OF_PEOPLES_AND_INCOME = {
    1:['$12,000', "$12,000 - $28,999", "$29,000 - $46,000", "$47,000"],
    2:['$16,000', '$16,000 - $38,999', '$39,000 - $63,999', '$64,000'],
    3:['$21,000', '$21,000 - $48,000', '$49,000 - $79,999', '$80,000'],
    4:['$37,000', '$37,000 - $90,999', '$91,000 - $149,999', '$150,000']
}

export const NO_OF_PEOPLES = Object.keys(NO_OF_PEOPLES_AND_INCOME);

export const getIncome = (people) =>{
    const result = NO_OF_PEOPLES_AND_INCOME[Number(people)];
    if(result) return result
    return []
}