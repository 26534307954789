import { sessionStorageKeys } from "../constants/localStorage";
import { v4 as uuid } from "uuid";

export function useEventID() {
  const isEventIdExists = sessionStorage.getItem(sessionStorageKeys.eventID);
  if (isEventIdExists) return isEventIdExists;
  else {
    const newEventID = uuid();
    sessionStorage.setItem(sessionStorageKeys.eventID, newEventID);
    return newEventID;
  }
}
